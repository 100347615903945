import React, { useState } from 'react'
import SideMenu from '../components/SideMenu'
import TopMenu from '../components/TopMenu'
import { useLocation } from 'react-router-dom'
import Analytics from '../components/HomeModules/Analytics'
import Users from '../components/HomeModules/Users'
import Trades from '../components/HomeModules/Trades'
import Receipts from '../components/HomeModules/Receipts'
import Referrals from '../components/HomeModules/Referrals'
import Withdrawal from '../components/HomeModules/Withdrawal'
import Activity from '../components/HomeModules/Activity'
import BrowsingUsers from '../components/HomeModules/BrowsingUsers'
import Ideas from '../components/HomeModules/Ideas'
import ChatBot from '../components/HomeModules/Chatbot'
import SoundAlert from '../components/SoundAlert'

function Home() {
  const params = useLocation()
  const link = params.pathname.split("/")[1]

  const [alert, setAlert] = useState(false);
  const [isUserInteracted, setIsUserInteracted] = useState(false);

  const handleNotification = () => {
    setAlert(true);
    setTimeout(() => setAlert(false), 100);  // Reset alert after 1 second
  };
  console.log(link)
  return (
    <div className='flex lg:h-[100vh]'>
      <SideMenu route={link}/>
      <div className={`bg-[#EEF5EF]lg:w-[90%] w-[100%] lg:h-[100%] lg:overflow-y-scroll lg:relative`}>
        <TopMenu route={link}/>
        {link === "analytics" && <Analytics/>}
        {link === "users" && <Users/>}
        {link === "trades" && <Trades/>}
        {link === "receipts" && <Receipts/>}
        {link === "referrals" && <Referrals/>}
        {link === "withdrawal" && <Withdrawal/>}
        {link === "activity" && <Activity/>}
        {link === "browsing-users" && <BrowsingUsers/>}
        {link === "ideas" && <Ideas/>}
        {link === "chatbot" && <ChatBot handleNotification={handleNotification} isUserInteracted={isUserInteracted} setIsUserInteracted={setIsUserInteracted}/>}
      </div>
      <SoundAlert alert={alert} isUserInteracted={isUserInteracted} />
    </div>
  )
}

export default Home