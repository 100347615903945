function convertDate(isoDate) {
    //   const isoDate = "2023-06-23T20:37:03.658Z";
  
    // Create a new Date object from the ISO string
    const date = new Date(isoDate);
  
    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
  
    // Format the date as "dd mm, yyyy"
    const formattedDate = `${day} ${month}, ${year}`;
  
    return formattedDate; // Output: 23 June, 2023
  }
  
  export function convertDateTime(isoDate) {
    // Create a new Date object from the ISO string
    const date = new Date(isoDate);
  
    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
  
    // Extract hours and minutes
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
  
    // Format the date and time as "dd mm, yyyy hh:mm"
    const formattedDate = `${day} ${month}, ${year} at ${hours}:${minutes}`;
  
    return formattedDate; // Output: "23 June, 2023 20:37"
  }
  
  export default convertDate;
  